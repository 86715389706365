(function ($) {

	//Finds <a> in hidden sliders and makes them not tabable, unless the slide is visible
	var customizedFunction = function () {
		$('.tns-slider .tns-item:not(.tns-slide-active) a').attr('tabindex', '-1');
		$('.tns-slider .tns-slide-active a').attr('tabindex', '0');
	};

	var container = document.querySelectorAll(".my-slider");

	if (container) {
		container.forEach(slider => {
			slider = tns({
				container: slider,
				nav: false,
				items: 1,
				mouseDrag: true,
				swipeAngle: false,
				lazyload: true,
				speed: 1000,
				autoplay: false,
				autoplayTimeout: 10000,
				autoplayHoverPause: true,
				autoplayButtonOutput: false,
				loop: false,
				rewind: true,
				arrowowKeys: true,
				controlsText: ["<p>forrige</p>", "<p>næste</p>"],
				onInit: customizedFunction(),
				responsive: {
					0: {
						disable: true,
					},
					700: {
						disable: false,
					},

				},
			});
			// bind function to event
			slider.events.on('indexChanged', customizedFunction);
		});

	}


	var columnCheck = $(".box-bg__inner--columns");
	
	if (columnCheck.hasClass('three_columns')) {
		var columnthree = document.querySelectorAll(".box-bg__inner--columns");
		if (columnthree) {
			columnthree.forEach(slider2 => {
				slider2 = tns({
					container: slider2,
					loop: false,
					mouseDrag: true,
					swipeAngle: false,
					speed: 400,
					freezable: true,
					arrowowKeys: true,
					controlsText: ["<p>forrige</p>", "<p>næste</p>"],
					controlsPosition: "bottom",
					navPosition: "bottom",
					rewind: true,
					gutter: 30,
					onInit: customizedFunction(),
					responsive: {
						0: {
							disable: false,
							items: 1,
						},
						900: {
							disable: false,
							items: 2,
						},
						1200: {
							disable: true,
						},
					},
				});
				// bind function to event
				slider2.events.on('indexChanged', customizedFunction);
			});
		}
	} else if (columnCheck.hasClass('two_columns')) {
		var columntwo = document.querySelectorAll(".box-bg__inner--columns");
		if (columntwo) {
			columntwo.forEach(slider2 => {
				slider2 = tns({
					container: slider2,
					loop: false,
					mouseDrag: true,
					swipeAngle: false,
					speed: 400,
					freezable: true,
					arrowowKeys: true,
					controlsText: ["<p>forrige</p>", "<p>næste</p>"],
					controlsPosition: "bottom",
					navPosition: "bottom",
					rewind: true,
					gutter: 30,
					onInit: customizedFunction(),
					responsive: {
						0: {
							disable: false,
							items: 1,
						},
						900: {
							disable: false,
							items: 2,
						},
						1200: {
							disable: true,
						},
					},
				});
				// bind function to event
				slider2.events.on('indexChanged', customizedFunction);
			});
		}
	} 
	

	var container3 = document.querySelectorAll(".news__inner");

	if (container3) {
		container3.forEach(slider3 => {
			slider3 = tns({
				container: slider3,
				loop: false,
				mouseDrag: true,
				swipeAngle: false,
				speed: 400,
				autoplay: false,
				freezable: true,
				arrowowKeys: true,
				controlsText: ["<p>forrige</p>", "<p>næste</p>"],
				controlsPosition: "bottom",
				navPosition: "bottom",
				edgePadding: 15,
				gutter: 30,
				rewind: true,
				onInit: customizedFunction(),
				responsive: {
					0: {
						disable: false,
						items: 1,
					},
					900: {
						disable: false,
						items: 2,
					},
					1200: {
						disable: true,
					},
				},
			});
			// bind function to event
			slider3.events.on('indexChanged', customizedFunction);
		});
	}

	var container4 = document.querySelectorAll(".cards-mini__inner");

	if (container4) {
		container4.forEach(slider4 => {
			slider4 = tns({
				container: slider4,
				loop: false,
				mouseDrag: true,
				swipeAngle: false,
				speed: 400,
				autoplay: false,
				freezable: true,
				arrowowKeys: true,
				controlsText: ["<p>forrige</p>", "<p>næste</p>"],
				controlsPosition: "bottom",
				navPosition: "bottom",
				edgePadding: 15,
				gutter: 30,
				rewind: true,
				onInit: customizedFunction(),
				responsive: {
					0: {
						disable: false,
						items: 1,
						gutter: 15,
					},
					700: {
						disable: false,
						items: 2,
						gutter: 15,
					},
					1100: {
						disable: true,
					},
				},


			});
			// bind function to event
			slider4.events.on('indexChanged', customizedFunction);
		});
	}


	var container6 = document.querySelectorAll(".news__innersmall");

	if (container6) {
		container6.forEach(slider6 => {
			slider6 = tns({
				container: slider6,
				loop: false,
				mouseDrag: true,
				swipeAngle: false,
				speed: 400,
				autoplay: false,
				freezable: true,
				arrowowKeys: true,
				controlsText: ["<p>forrige</p>", "<p>næste</p>"],
				controlsPosition: "bottom",
				navPosition: "bottom",
				edgePadding: 15,
				gutter: 30,
				rewind: true,
				onInit: customizedFunction(),
				responsive: {
					0: {
						disable: false,
						items: 1,
					},
					900: {
						disable: false,
						items: 2,
					},
					1200: {
						disable: true,
						gutter: 0,
						edgePadding: 0,
					},
				},
			});
			// bind function to event
			slider6.events.on('indexChanged', customizedFunction);
		});
	}

	var container7 = document.querySelectorAll(".medarbejdere__inner--content");

	if (container7) {
		container7.forEach(slider7 => {
			slider7 = tns({
				container: slider7,
				loop: false,
				mouseDrag: true,
				swipeAngle: false,
				speed: 400,
				autoplay: false,
				freezable: true,
				arrowowKeys: true,
				controlsText: ["<p>forrige</p>", "<p>næste</p>"],
				controlsPosition: "bottom",
				navPosition: "bottom",
				edgePadding: 15,
				gutter: 30,
				rewind: true,
				onInit: customizedFunction(),
				responsive: {
					0: {
						disable: false,
						items: 1,
					},
					700: {
						disable: false,
						gutter: 15,
						items: 2,
					},
					1100: {
						disable: true,
						gutter: 0,
						edgePadding: 0,
					},
				},
			});
			// bind function to event
			slider7.events.on('indexChanged', customizedFunction);
		});
	}
})(jQuery);
