(function ($) {

	// Select all links with hashes
	$('a[href*="#"]')
		// Remove links that don't actually link to anything
		.not('[href="#"]')
		.not('[href="#0"]')
		.not('.faq a[href*="#"]')
		.not('[href*="#content"]')
		.click(function (event) {
			// On-page links
			if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
				// Figure out element to scroll to
				var target = $(decodeURIComponent(this.hash));

				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

				// Does a scroll target exist?
				if (target.length) {
					// Only prevent default if animation is actually gonna happen
					event.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top + -230
					}, 1000, function () {
						// Callback after animation
						// Must change focus!
						var $target = $(target);
						$target.focus();
						if ($target.is(":focus")) { // Checking if the target was focused
							return false;
						} else {
							$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
							$target.focus(); // Set focus again
						}
					});
				}
			}
		});

	$('.site-footer__scrolltop').click(function (e) {
		e.preventDefault();
		window.scrollTo({ top: 0, behavior: 'smooth' });
	});

	var nposts = 6;

	$('.cat-list__item').on('click', function (e) {
		e.preventDefault();
		$('.cat-list__item').removeClass('active');
		$(this).addClass('active');
		var cat = $(this).data('slug');
		$('.load_more').attr('data-slug', cat);
		$('.load_more').attr('data-posts', "6");
		nposts = 6;

		$.ajax({
			type: 'POST',
			url: '/wp-admin/admin-ajax.php',
			dataType: 'html',
			data: {
				action: 'filter_news',
				category: $(this).data('slug'),
			},
			beforeSend: function(){
				$('.nyheder__left--overlay').show();
			},
			complete: function(){
				$('.nyheder__left--overlay').hide();
			},
			success: function (res) {
				$('.nyheder__inner').html(res);
			}
		});

	});

	$(document).on('click', '.load_more', function () {
		var dataposts = $(this).data('posts');
		nposts += dataposts;
		$('.load_more').attr('data-posts', nposts);

		$.ajax({
			type: 'POST',
			url: '/wp-admin/admin-ajax.php',
			dataType: 'html',
			data: {
				action: 'load_news',
				category: $(this).data('slug'),
				posts: nposts,
			},
			beforeSend: function(){
				$('.nyheder__left--overlay').show();
			},
			complete: function(){
				$('.nyheder__left--overlay').hide();
			},
			success: function (res) {
				$('.nyheder__inner').html(res);
			}
		});
	});

	$(".gform_wrapper button:submit").addClass("consent_paged").clone().appendTo(".ginput_container_consent").addClass("consent_normal");

	$(".job-page__inner--right__outer--click").click(function () {
		jobOpen($(this));
	});
	function jobOpen(element) {
		var parent = element.parent();
		parent.toggleClass("job-active");
		var height = parent.find('.job-page__inner--right').height() + 90;
		if(!parent.hasClass("job-active")) {
			parent.css( "max-height", "150px" );
		} else {
			parent.css( "max-height", height );
		}
	}
	jobOpen($(".job-page__inner--right__outer--click"));


	$(".nyheder__right--mobile__click").click(function () {
		var parent = $(this).parent();
		parent.toggleClass("nyheder-active");
		var height = parent.find('.nyheder__right--press').height() + 150;
		if(!parent.hasClass("nyheder-active")) {
			parent.css( "max-height", "150px" );
		} else {
			parent.css( "max-height", height );
		}
	});

	

})(jQuery);
