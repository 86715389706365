
(function ($) {

	$('.faq__right--copyButton').click(function (e) {
		e.preventDefault();
		var id = $(this).val();
		navigator.clipboard.writeText(id);
	});

	//Venstre
	//FAQ OUTER
	var faqContainer = $('.faq__inner');

	var leftCategories = $('.faq__left--category').attr('aria-expanded', 'false');
	var leftCategoriesSub = $('.faq__left--sub').hide();



	//Højre
	//Kategori: Affald, Vand, Spildevand etc.
	var rCategory = $('.faq__right__category').hide().attr('tabindex', 0).attr('aria-expanded', 'false');
	//Underkategori: Affaldsbeholdere, Sortering
	var questionsO = $('.faq__right--sub');
	//Underkategori indhold
	var rfaqInner = $('.faq__right--subcategory').attr('aria-expanded', 'false');
	var answers = $('.faq__right--question__answer');
	var allQuestions = $('.faq__right--allQuestions').hide();

	//Underk. spørgsmål
	var questions = $('.faq__right--question').attr('aria-expanded', 'false');
	//Vis første kategori

	function showFirst() {
		var firstPanelLeft = leftCategories.first().show().addClass('faq-sub-active').attr('aria-expanded', 'true');
		leftCategoriesSub.first().show();

		var rFirstCategory = rCategory.first().show().addClass('right-cat-active').attr('aria-expanded', 'true');
		//Vis
		rFirstCategory.find('.faq__right--question__answer').hide();
	}


	function hideQuestions() {
		allQuestions.slideUp();
	}
	function hideAnswers() {
		answers.slideUp();
		questions.removeClass('question-active').attr('aria-expanded', 'false');
	}
	function hideLeftSub() {
		leftCategoriesSub.slideUp();
	}
	function hideSubCategories() {
		allQuestions.slideUp();
		rfaqInner.removeClass('right-active').attr('aria-expanded', 'false');
		leftCategories.find('.faq__left--sub__inner').removeClass('faq-left-inner-active').attr('aria-expanded', 'false');
	}


	if (window.location.hash) {
		var hash = window.location.hash.substring(1);

		var element = faqContainer.find('#' + hash + '').parent();
		console.log(element);
		if (hash.indexOf('_') > -1) {
			var tester = hash.split('#').pop().split('-').pop().split('_');
			console.log(tester[1]);
		}
		if (element.length === 0) {
			showFirst();
		} else {
			var outer = element.parent().parent().parent();
			var href = hash.split('#').pop().split('-');
			var href2 = hash.split('#').pop().split('-').pop().split('_');
			var hrefs = [];
			hrefs.push(href[0]);
			hrefs.push(href2[0]);
			hrefs.push(href2[1]);
			console.log(hrefs);

			var foundCategory = faqContainer.find('#' + hrefs[0] + '').parent();
			var foundSubC = foundCategory.find('#' + hrefs[0] + '-' + hrefs[1]);
			var foundLeftCategory = faqContainer.find('#Left-' + hrefs[0] + '').addClass('faq-sub-active').attr('aria-expanded', 'true');
			var foundQ = foundCategory.find('#' + hrefs[0] + '-' + hrefs[1] + '_' + hrefs[2]);


			if (!foundSubC.hasClass('right-active')) {
				if (hrefs.length === 3) {
					element.parent().addClass('faq-left-inner-active').attr('aria-expanded', 'true');
					rfaqInner.removeClass('right-active').attr('aria-expanded', 'false');
					allQuestions.slideUp();
					foundCategory.slideDown();
					var foundLeftSub = faqContainer.find('#Left-' + hrefs[0] + '-' + hrefs[1] + '');
					foundLeftSub.addClass('faq-left-inner-active').attr('aria-expanded', 'true');
					foundLeftSub.parent().slideDown();
					rCategory.removeClass('right-cat-active').attr('aria-expanded', 'false');
					foundCategory.addClass('right-cat-active').attr('aria-expanded', 'true');

					//Showing first question
					questions.removeClass('question-active').attr('aria-expanded', 'false');
					foundSubC.addClass('right-active').attr('aria-expanded', 'true');
					foundSubC.find('.faq__right--question__answer').slideUp();
					foundSubC.find('.faq__right--allQuestions').slideDown();
					foundQ.addClass('question-active question-focus').attr('aria-expanded', 'true');
					foundQ.find('.faq__right--question__title button').first().focus();
					foundQ.slideDown();
					foundQ.find('.faq__right--question__answer').first().slideDown();
				} else if (hrefs.length === 2) {
					element.parent().addClass('faq-left-inner-active').attr('aria-expanded', 'true');
					rfaqInner.removeClass('right-active').attr('aria-expanded', 'false');
					allQuestions.slideUp();
					foundCategory.slideDown();
					var foundLeftSub = faqContainer.find('#Left-' + hrefs[0] + '-' + hrefs[1] + '');
					foundLeftSub.addClass('faq-left-inner-active').attr('aria-expanded', 'true');
					foundLeftSub.parent().slideDown();
					rCategory.removeClass('right-cat-active').attr('aria-expanded', 'false');
					foundCategory.addClass('right-cat-active').attr('aria-expanded', 'true');

					//Showing first question
					questions.removeClass('question-active').attr('aria-expanded', 'false');
					foundSubC.addClass('right-active').attr('aria-expanded', 'true');
					foundSubC.find('.faq__right--question__answer').slideUp();
					foundSubC.find('.faq__right--allQuestions').slideDown();
					foundSubC.find('.faq__right--question').first().addClass('question-active').attr('aria-expanded', 'true');
					foundSubC.slideDown();
					foundSubC.find('.faq__right--question__answer').first().slideDown();
				} else {
					element.parent().addClass('faq-left-inner-active').attr('aria-expanded', 'true');
					rfaqInner.removeClass('right-active').attr('aria-expanded', 'false');
					allQuestions.slideUp();
					foundCategory.slideDown();
					leftCategories.find('.faq__left--sub__inner').removeClass('faq-left-inner-active').attr('aria-expanded', 'false');
					foundLeftCategory.find('.faq__left--sub').slideDown();
					foundLeftCategory.find('.faq__left--sub__inner').first().addClass('faq-left-inner-active').attr('aria-expanded', 'true');


					//Showing first question
					questions.removeClass('question-active').attr('aria-expanded', 'false');
					var firstSub = foundCategory.find('.faq__right--subcategory').first().addClass('right-active').attr('aria-expanded', 'true');
					firstSub.find('.faq__right--allQuestions').slideDown();
					var firstQ = firstSub.find('.faq__right--question').first().addClass('question-active').attr('aria-expanded', 'true');
					firstQ.first().slideDown();
					firstQ.first().find('.faq__right--question__answer').slideDown();
				}

			}
		}


	} else {
		showFirst();
	}

	//Venstre side
	function openLeft(panel) {
		var target = panel.next();
		var outer = panel.parent();

		if (!outer.hasClass('faq-sub-active')) {

			leftCategories.removeClass('faq-sub-active').attr('aria-expanded', 'false');
			leftCategoriesSub.slideUp();
			outer.addClass('faq-sub-active').attr('aria-expanded', 'true');
			target.slideDown();

			//Finder og viser korrekt element i højre side
			var href = panel.prop('href').split('#');

			var rFoundLabel = faqContainer.find('#' + href[1] + '').parent();
			if (!rFoundLabel.hasClass('right-cat-active')) {
				leftCategories.find('.faq__left--sub__inner').removeClass('faq-left-inner-active').attr('aria-expanded', 'false');
				hideSubCategories();
				rCategory.slideUp().removeClass('right-cat-active').attr('aria-expanded', 'false');
				rFoundLabel.addClass('right-cat-active').slideDown().attr('aria-expanded', 'true');

				//Showing first question
				questions.removeClass('question-active').attr('aria-expanded', 'false');
				rFoundLabel.find('.faq__right--allQuestions:not(:eq(0))').slideUp();

			}

		}
	}

	$('.faq__left > .faq__left--wrap > .faq__left--category > a').click(function () {
		openLeft($(this));

		return false;
	});
	$('.faq__left > .faq__left--wrap > .faq__left--category > a').keydown(function (event) {
		var keyCode = event.which; // Capture which key was pressed
		switch (keyCode) {
			case 38:
				hideLeftSub();
				leftCategories.removeClass('faq-sub-active').attr('aria-expanded', 'false');
				leftCategories.find('.faq__left--sub__inner').removeClass('faq-left-inner-active').attr('aria-expanded', 'false');
				// The up arrow was pressed
				// If the focused accordion is open, you should close it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 40:
				openLeft($(this));
				// The down key was pressed
				// If the focused accordion is closed, you should open it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 13: case 32:
				openLeft($(this));
				// Either the enter key or space bar was pressed
				// You should toggle the focused accordion.
				// If it is open, close it now; if it is closed, open it now.
				event.preventDefault(); // Prevent the page from scrolling
				break;
			default:
		}
	});

	//Venstre indre

	function subLeft(panel) {
		var outer = panel.parent().parent().parent();
		var href = panel.prop('href').split('#').pop().split('-');
		var hrefsub = panel.prop('href').split('#');
		//alert('1: ' + href[0] + ' |  2: ' + hrefsub[1]);

		var foundCategory = faqContainer.find('#' + href[0] + '').parent();
		var foundSubC = foundCategory.find('#' + hrefsub[1]);

		if (!foundSubC.hasClass('right-active')) {
			leftCategories.find('.faq__left--sub__inner').removeClass('faq-left-inner-active').attr('aria-expanded', 'false');
			panel.parent().addClass('faq-left-inner-active').attr('aria-expanded', 'true');
			rfaqInner.removeClass('right-active').attr('aria-expanded', 'false');
			allQuestions.slideUp();
			hideAnswers();
			//Showing first question
			questions.removeClass('question-active').attr('aria-expanded', 'false');
			foundSubC.addClass('right-active').attr('aria-expanded', 'true');
			foundSubC.find('.faq__right--question').slideDown();
			foundSubC.find('.faq__right--allQuestions').slideDown();
			foundSubC.slideDown();

		}
	}

	$('.faq__left--sub > .faq__left--sub__inner > a').click(function () {
		subLeft($(this));

		return false;
	});
	$('.faq__left--sub > .faq__left--sub__inner > a').keydown(function (event) {
		var keyCode = event.which; // Capture which key was pressed
		switch (keyCode) {
			case 38:
				hideLeftSub();
				leftCategories.removeClass('faq-sub-active').attr('aria-expanded', 'false');
				leftCategories.find('.faq__left--sub__inner').removeClass('faq-left-inner-active').attr('aria-expanded', 'false');
				// The up arrow was pressed
				// If the focused accordion is open, you should close it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 40:
				subLeft($(this));
				// The down key was pressed
				// If the focused accordion is closed, you should open it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 13: case 32:
				subLeft($(this));
				// Either the enter key or space bar was pressed
				// You should toggle the focused accordion.
				// If it is open, close it now; if it is closed, open it now.
				event.preventDefault(); // Prevent the page from scrolling
				break;
			default:
		}
	});

	//Højre side

	//Højre ydre
	function categoryRight(panel1) {
		var target = panel1.next();
		var outer = panel1.parent();

		var id = outer.attr('id');
		var leftDiv = $('a[href="#' + id + '"]').parent();

		if (!outer.hasClass('right-active')) {
			//Question
			//rFirstCategory.find('.faq__right--question__answer').slideUp();
			leftCategories.find('.faq__left--sub__inner').removeClass('faq-left-inner-active').attr('aria-expanded', 'false');
			rfaqInner.removeClass('right-active').attr('aria-expanded', 'false');
			questions.removeClass('question-active').attr('aria-expanded', 'false');
			allQuestions.slideUp();
			hideAnswers();
			leftDiv.addClass('faq-left-inner-active').attr('aria-expanded', 'true');
			outer.addClass('right-active').attr('aria-expanded', 'true');
			outer.find('.faq__right--allQuestions').slideDown();


		} else {
			hideSubCategories();
		}
	}
	$('.faq__right--subcategory--label').click(function () {
		categoryRight($(this));

		return false;
	});
	$('.faq__right--subcategory--label').keydown(function (event) {
		var keyCode = event.which; // Capture which key was pressed
		switch (keyCode) {
			case 38:
				hideAnswers();
				// The up arrow was pressed
				// If the focused accordion is open, you should close it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 40:
				//hideAnswers();
				categoryRight($(this));
				// The down key was pressed
				// If the focused accordion is closed, you should open it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 13: case 32:
				categoryRight($(this));
				// Either the enter key or space bar was pressed
				// You should toggle the focused accordion.
				// If it is open, close it now; if it is closed, open it now.
				event.preventDefault(); // Prevent the page from scrolling
				break;
			default:
		}
	});

	//Højre indre
	function innerRight(panel) {
		var target = panel.next();
		var outer = panel.parent();
		if (!outer.hasClass('question-active')) {
			answers.slideUp();
			questions.removeClass('question-active').attr('aria-expanded', 'false');
			outer.parent().find('.faq__right--question__answer').slideUp();
			outer.addClass('question-active').attr('aria-expanded', 'true');
			target.slideDown();
			var showa = outer.find('.faq__right--question__answer').slideDown();
		} else {
			hideAnswers();
		}
	}

	$('.faq__right--question__title').click(function () {
		innerRight($(this));

		return false;
	});
	$('.faq__right--question__title').keydown(function (event) {
		var keyCode = event.which; // Capture which key was pressed
		switch (keyCode) {
			case 38:
				hideAnswers();
				// The up arrow was pressed
				// If the focused accordion is open, you should close it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 40:
				//hideAnswers();
				innerRight($(this));
				// The down key was pressed
				// If the focused accordion is closed, you should open it now
				event.preventDefault(); // prevent the page from scrolling
				break;
			case 13: case 32:
				innerRight($(this));
				// Either the enter key or space bar was pressed
				// You should toggle the focused accordion.
				// If it is open, close it now; if it is closed, open it now.
				event.preventDefault(); // Prevent the page from scrolling
				break;
			default:
		}
	});


	// This function captures any keyboard presses while an accordion is in focus (tabbed to by a keyboard user)
	$('.faq__right__category').keydown(function (event) {
		var keyCode = event.which; // Capture which key was pressed
		switch (keyCode) {

			case 35:
				// The end key was pressed
				// You should move focus to the last accordion on the page now
				event.preventDefault(); // Prevent the page from scrolling
				var last = $(this).find('.faq__right--subcategory--label button').last();
				last.focus();
				break;
			case 36:
				// The home key was pressed
				// You should move focus to the first accordion on the page now
				event.preventDefault(); // Prevent the page from scrolling
				var first = $(this).find('.faq__right--subcategory--label button').first();
				first.focus();
				break;
			case 37:
				// The left arrow key was pressed
				// You should move focus to the first accordion on the page now
				event.preventDefault(); // Prevent the page from scrolling
				var leftside = faqContainer.find('.faq__left--category a').first();
				leftside.focus();
				break;
			default:
			// A key was pressed, but it is not actionable
		}
	});
	$('.faq__left--wrap').keydown(function (event) {
		var keyCode = event.which; // Capture which key was pressed
		console.log(keyCode);
		switch (keyCode) {

			case 35:
				// The end key was pressed
				// You should move focus to the last accordion on the page now
				event.preventDefault(); // Prevent the page from scrolling
				var last = $(this).find('.faq__right--subcategory--label button').last();
				last.focus();
				break;
			case 36:
				// The home key was pressed
				// You should move focus to the first accordion on the page now
				event.preventDefault(); // Prevent the page from scrolling
				var first = $(this).find('.faq__right--subcategory--label button').first();
				first.focus();
				break;
			case 39:
				// The right arrow key was pressed
				// You should move focus to the first accordion on the page now
				event.preventDefault(); // Prevent the page from scrolling
				var rightside = faqContainer.find('.right-cat-active .faq__right--subcategory--label button').first();
				rightside.focus();
				break;
			default:
			// A key was pressed, but it is not actionable
		}
	});


})(jQuery);



